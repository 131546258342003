import React from 'react'
import styled from "@emotion/styled"

const Headone = styled.h1`
line-height: 1.15em;
font-size: 1.0625rem;
font-weight: 800;
letter-spacing: .75px;
height: 8vh;
&.noheight {
    height: auto;
}
`

const Title = ({children, className}) => {
    return (
        <Headone className={className}>{children}</Headone>
    )
}

export default Title